import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil - Bienvenue à Serenity Escape Resort
			</title>
			<meta name={"description"} content={"Là où la détente rencontre l'élégance - Serenity Escape, votre sanctuaire de tranquillité\n"} />
			<meta property={"og:title"} content={"Accueil - Bienvenue à Serenity Escape Resort"} />
			<meta property={"og:description"} content={"Là où la détente rencontre l'élégance - Serenity Escape, votre sanctuaire de tranquillité\n"} />
			<meta property={"og:image"} content={"https://brittanybeaches.live/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brittanybeaches.live/images/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brittanybeaches.live/images/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brittanybeaches.live/images/images.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Serenity Escape Resort
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						Niché au cœur de paysages sereins, Serenity Escape Resort est votre billet pour une retraite revigorante. Des soins de spa apaisants au confort luxueux de nos suites, chaque aspect de notre complexe est conçu pour offrir une oasis de calme au voyageur fatigué.
					</Text>
					<Button
						background="--color-primary"
						padding="12px 28px 12px 28px"
						border-radius="4px"
						font="normal 400 18px/1.5 --fontFamily-sans"
						margin="20px 0px 60px 0px"
						sm-margin="0px 0px 40px 0px"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Contactez-nous
					</Button>
				</Box>
				<Image
					src="https://brittanybeaches.live/images/1.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 0px -160px"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0"
			>
				<Image
					src="https://brittanybeaches.live/images/2.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					margin="80px 0px 0px 0px"
					object-fit="cover"
					sm-margin="0 0px 0px 0px"
				/>
				<Image
					src="https://brittanybeaches.live/images/3.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
				/>
				<Image
					src="https://brittanybeaches.live/images/5.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					margin="0px 0px 80px 0px"
					sm-margin="0px 0px 0 0px"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://brittanybeaches.live/images/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Découvrez le charme du Serenity Escape Resort
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Laissez-vous séduire par l'atmosphère paisible du Serenity Escape Resort, où chaque instant est conçu pour offrir paix et détente. Notre établissement est un mélange harmonieux de beauté naturelle et de confort raffiné, garantissant un séjour qui rajeunit l'esprit, le corps et l'âme.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Все, що вам потрібно
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Pourquoi choisir Serenity Escape Resort ?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Détendez-vous dans le luxe : retirez-vous dans nos chambres élégantes qui offrent des vues à couper le souffle et un confort douillet qui vous promet un sommeil réparateur.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Apaisez vos sens
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Notre spa à service complet propose une gamme de traitements pour éliminer le stress et rétablir l'équilibre.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Dîner avec style
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Savourez une cuisine gastronomique dans notre restaurant sur place, où chaque plat est une célébration de la saveur et du goût.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Les plaisirs de la détente
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Faites un plongeon dans notre piscine tranquille ou détendez-vous avec un livre en écoutant le doux bruit de l'eau.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Des soins personnalisés
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Faites l'expérience de services sur mesure grâce à notre personnel dévoué qui répond à vos moindres besoins.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-10">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
			/>
			<Box min-width="100px" min-height="100px" margin="0px 0px 16px 0px">
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Se connecter avec Serenity Escape Resort
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px 54px"
					margin="0px 0px 25px 0px"
					md-grid-template-columns="1fr"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
							Situation : Loin du tumulte de la vie quotidienne, notre station est un sanctuaire où la nature et le confort coexistent. Vous ne trouverez ce lieu qu'au 48 Rte Neuve du Col-de-la-Luere, 69290 Grézieu-la-Varenne, France.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
							Contact : Pour les réservations, veuillez appeler le +33 6 58 09 75 88 ou nous envoyer un courriel à contact@brittanybeaches.live.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});